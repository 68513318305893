import axios from "axios";

export * from "./routes.constants";

//export const baseURL = "https://f8.codester.dev/web/api";
export const baseURL = "https://f8.codester.dev/api";

//export const baseURL = "https://hand.codester.dev/mobile/api/";
export const photoBaseURL = "https://f8.codester.dev/storage/";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Headers": "Content-Type",
  },
});

const addTokenToRequest = async (req) => {
  const token = sessionStorage.getItem("#f8WEB#");
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;

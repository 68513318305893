import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Box,
  ChakraProvider,
  createStandaloneToast,
  Text,
} from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import { defineStyleConfig } from "@chakra-ui/react";
const { toast, ToastContainer } = createStandaloneToast();

const Input = defineStyleConfig({
  baseStyle: (props) => ({
    field: {
      background: props.colorMode === "light" ? "#fff" : "#010000",
      boxShadow: "none",
    },
  }),
  defaultProps: {
    variant: null, // this makes custom input background color work
  },
});
const Textarea = defineStyleConfig({
  baseStyle: (props) => ({
    field: {
      background: props.colorMode === "light" ? "#fff" : "#010000",
      boxShadow: "none",
    },
  }),
  defaultProps: {
    variant: null, // this makes custom input background color work
  },
});
const Button = defineStyleConfig({
  baseStyle: {
    background: "#4461F2",
    boxShadow: "none",
  },
  defaultProps: {
    variant: null, // this makes custom input background color work
  },
});
const Select = defineStyleConfig({
  baseStyle: {
    field: {
      bg: "none",
      fontSize: ".8em",

      // width: "auto",
    },
    icon: {
      fontSize: ".9em",
    },
  },
  defaultProps: {
    variant: null, // this makes custom input background color work
  },
});

const theme = extendTheme({
  components: {
    Input,
    Textarea,
    Button,
    Select,
  },
  styles: {
    global: (props) => ({
      // styles for the `body`
      body: {
        fontFamily: "Poppins",
        fontSize: ".8em",
        background: props.colorMode === "light" ? "#F3F3F3" : "#09192E",
      },
      heading:{fontFamily:`'Poppins-Medium', Helvetica`},

      input: {
        _placeholder: {
          color: props.colorMode === "light" ? "#cccccc" : "grey",
        },
      },
    }),
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
      <ToastContainer />
    </ChakraProvider>
  </React.StrictMode>
);

export const errorNotifier = (errorMessage) => {
  console.log('errorMessage:',errorMessage);
  return toast({
    description:
      typeof errorMessage === "string" ? errorMessage : errorMessage,
    status: "error",
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });
};

export const successNotifier = (info) => {
  return toast({
    description: info,
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top",
  });
};

export const shortNotifier = (info) => {
  return toast({
    description: info,
    duration: 5000,
    isClosable: true,
    position: "bottom",
  });
};

export const LoadingMessage = (title = false) => {
  return toast({
    position: "bottom",
    duration: 900,
    render: () => (
      <Box borderRadius="2px" color="#fff" bg="#bfbf36" p={2} w="100px">
        <Text textAlign="center" fontWeight="bold">
          {title ? title : "Loading"}
        </Text>
      </Box>
    ),
  });
};
reportWebVitals();
